import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { Card, Breadcrumb, Table, Spinner } from 'react-bootstrap';


// images import
import userlogo from '../../../images/avatarpic3.jpg';
import CanceledMySectorsTRLoop from '../mysectors-compo/CanceledMySectorsTRLoop';
import { Link, useParams } from 'react-router-dom';
import PnrTRLoop from './PnrTRLoop';
import axios from 'axios';
import { API_BASE_URL, getBearerToken } from '../../../functions';
import BookTicket from '../../Modals/BookTicket';

const PnrDetailPage = () => {
  const [pnrData, setPnrData] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const pnr = useParams()
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedPnr, setSelectedPnr] = useState({ date: '', pnr: '', price: 0 });
  const { slug } = useParams()

  // console.log(pnr, slug);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  useEffect(() => {
    setLoading(true)
    axios.post(`${API_BASE_URL}/pnr-details`, {
      pnr: pnr
    }, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    })
      .then(response => {
        // Handle the response data
        setPnrData(response?.data.data)
        setLoading(false)
      }).catch(error => {
        // Handle errors
        setError(error?.response?.data.message);
        console.error(error?.response?.data.message);
        setLoading(false)
      });
  }, [])


  // console.log(pnrData);


  return (
    <>
      <div className={'main_heading mb-5 d-flex align-items-center justify-content-between'}>
        <div>
          <h4 className='mb-1'>PNR : {slug}</h4>
          <Breadcrumb className={'BreadCrumbLinkks m-0 p-0'}>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/dashboard' }}>Dashboard</Breadcrumb.Item>
            {/* <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/bookings' }}>Bookings</Breadcrumb.Item> */}
            <Breadcrumb.Item active>{slug}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="text-end">
          {/*  */}

          <Link to={'#'} className='ms-auto btn greenbg text-white fontsize14 fontweight700 mb-3'
            style={{ minWidth: '160px', maxWidth: '160px' }}
            onClick={(e) => {
              setSelectedPnr({ date: pnrData?.date, pnr: pnrData?.pnr , price: pnrData?.price,updated_at: pnrData.updated_at });
              setModalShow(true);
            }}
          >Book Ticket</Link>
          {/*  */}
          {pnrData?.results?.length ? <div className="d-flex ms-auto align-items-center">
            <Link to={pnrData?.pnr_pdf_view_link} target='_blank' className="me-3 fontsize14 fontweight600">
              View
            </Link>
            <Link to={pnrData?.pnr_excel_downlaod} className=" btn seabluebg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
              Download xlsx
            </Link>
          </div>:null}
        </div>
      </div>
      <>
        <Card className={'mb-5'} style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
          <Card.Header className='px-3 px-lg-4 py-3 d-sm-flex align-items-center' style={{ background: 'transparent', border: 'none', }}>
            <h5 className={'fontsize18 blacktext fontweight600 mb-3 mb-sm-0'}><span className={'greentext me-2'}>{slug}</span></h5>
            <div className={'ms-auto d-flex align-items-center'}>
              <h5 className={'fontweight700 me-lg-4 me-2 fontsize14 paragraphtext'}>Total: <span className={'blacktext'}>{pnrData.total}</span></h5>
              <h5 className={'fontweight700 fontsize14 me-lg-4 me-2 greentext'}>Sold: <span className={'blacktext'}>{pnrData.sold}</span></h5>
              <h5 className={'fontweight700 fontsize14 redtext'}>Remaining: <span className={'blacktext'}>{pnrData.remaining}</span></h5>
            </div>
          </Card.Header>
          <Card.Body className={'p-0 mb-3'}>
            <Table responsive={'sm'} className={'mb-0 allView'}>
              <thead className={'thead-dark'}>
                <tr>
                  <th>Airline</th>
                  <th>Sector</th>
                  <th>Date</th>
                  <th>payment status</th>
                  <th>Agent Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pnrData?.results?.length > 0 ?
                  pnrData?.results?.map((val) => {
                    return (
                      <PnrTRLoop
                        key={val.key}
                        id={val.id}
                        usericon={val.air_line_logo}
                        userTitle={val.air_line}
                        sectorName={val.sector}
                        shortName={val.date}
                        agentName={val.agent_name}
                        paymentStatus={val.payment_status}
                        confirmed={val.confirm}
                        editData={val}
                      />
                    )
                  })
                  :
                  <div className={'mt-3 w-100 text-center'}>
                    <p>{error}</p>
                  </div>
                }
              </tbody>
              {loading &&
                <div className={'mt-3 w-100 text-center'}>
                  <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                </div>
              }
            </Table>
          </Card.Body>
        </Card>
        <Card className='' style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
          <Card.Header className='px-3 px-lg-4 py-3' style={{ background: 'transparent', border: 'none', }}><h5 className={'fontsize18 blacktext fontweight600'}><span className={'redtext me-2'}>Canceled</span></h5></Card.Header>
          <Card.Body className={'p-0 mb-3'}>
            <Table responsive={'sm'} className={'mb-0 allView'}>
              <thead className={'thead-dark'}>
                <tr>
                  <th>Airline</th>
                  <th>Sector</th>
                  <th>Date</th>
                  <th>payment status</th>
                  <th>Agent Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pnrData?.canceled?.length > 0 ?
                  pnrData?.canceled?.map((val) => {
                    return (
                      <PnrTRLoop
                        key={val.key}
                        id={val.id}
                        usericon={val.air_line_logo}
                        userTitle={val.air_line}
                        sectorName={val.sector}
                        shortName={val.date}
                        agentName={val.agent_name}
                        paymentStatus={val.payment_status}
                        cancel_status={val.cancel_status}
                      />
                    )
                  })
                  :
                  <div className={'mt-3 w-100 text-center'}>
                    <p>{error}</p>
                  </div>
                }
              </tbody>
              {loading &&
                <div className={'mt-3 w-100 text-center'}>
                  <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                </div>
              }
            </Table>
          </Card.Body>
        </Card>
      </>
      {modalShow &&
        <BookTicket show={modalShow} setShow={setModalShow} selectedPnr={selectedPnr} />
      }
    </>
  )
}

export default PnrDetailPage;