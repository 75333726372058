import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Dropdown, Spinner, Table } from 'react-bootstrap';

// import images
import verticalicon from '../.././../images/verticalicon-dots.svg';
import { API_BASE_URL, formatDate, formatDateAndTime, getBearerToken, pickTimeWithAMPM, setLoadingState } from '../../../functions';
import axios from 'axios';
import AddTicketPay from '../../Modals/AddTicketPay';
import EditTicket from '../../Modals/EditBooking';
import ConfirmBooking from '../../Modals/ConfirmBooking';
import { useAuth } from '../auth/AuthProvider';
import toast from 'react-hot-toast';
import ConfirmationPopup from '../../Modals/ConfirmationPopup';
import TimeUpdate from '../../Modals/TimeUpdate';

const BookingsTRLoop = ({
    data = [],
    isLoading,
    loadingKey,
    toastMessage,
    setToastMessage,
    showToast,
    setShowToast }) => {

    const [show, setShow] = useState(false)
    const [showCon, setShowCon] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showTime, setShowTime] = useState(false)
    const { token } = useAuth()
    const [selectedPnr, setSelectedPnr] = useState({ date: '', pnr: '' });
    const [targetBooking, setTargetBooking] = useState('')
    const [confirmation, setConfirmation] = useState({ show: false, target: '' })




    const handalPayment = (booking) => {
        setTargetBooking(booking)
        setShow(true)
    }
    const handalTime = (booking) => {
        setTargetBooking(booking)
        setShowTime(true)
    }
    const handalCancel = (e, id) => {
        setConfirmation({ show: true, target: 'cancel' })
        setTargetBooking(e)
    };

    const handleConfirm = (e) => {
        e.preventDefault()
        setConfirmation({ show: false, target: '' })
        setLoadingState(true, 'CANCEL_BOOKING');
        const tid = toast.loading("Please wait...");
        axios.get(`${API_BASE_URL}/cancelbooking/${targetBooking}`, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // Handle the response data
            toast.success('Ticket cancelled successfully!');
            toast.dismiss(tid);
            setLoadingState(false, '');
            // setToastMessage({ type: 'success', message: 'Ticket cancelled successfully!' });
            setTimeout(() => {
                window.location.reload();
            }, 2000);

        }).catch(error => {
            // Handle errors
            // setToastMessage({ type: 'failde', message: error.response.data.message });
            toast.error(error.response.data.message, {
                id: tid,
            });
            setShowToast('show')
            setLoadingState(false, '');


        });
    }


    const handalEdit = (id) => {
        setShowEdit(true)
        setTargetBooking(id)
    }
    const handalShow = (booking) => {
        setShowCon(true);
        setTargetBooking(booking)
    }

    // console.log(data);
    return (
        <>
            <Table responsive="sm" className="mb-0 allView">
                <thead className="thead-dark">
                    <tr>
                        <th>Id</th>
                        <th>PNR</th>
                        <th>Airline</th>
                        <th>Sector</th>
                        <th>Date</th>
                        {token.role === 'admin' && <th>Pay. Status</th>}
                        <th>Pse. Name</th>
                        {token.role === 'admin' && <th>Agent</th>}
                        <th>Booking Date</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((booking, index) => (
                        <tr key={booking.id}>
                            <td>
                                <Link to={`/dashboard/book/${booking.id}`} className="text-decoration-none bluetext fontweight600">
                                    {booking.id} - view booking
                                </Link>
                            </td>
                            <td>
                                {token.role === 'admin' ?
                                    <Link to={`/dashboard/pnr/${booking.pnr}`} className="text-decoration-none bluetext fontweight600">
                                        {booking.pnr}
                                    </Link>
                                    :
                                    <>{booking.pnr}</>
                                }
                            </td>
                            <td>
                                <Link to="#" className="d-flex align-items-center text-decoration-none">
                                    <div className="AllPhoto">
                                        <img className="img-fluid" src={booking.airline_logo} alt="icon" />
                                    </div>
                                </Link>
                            </td>
                            <td>{booking.sector}</td>
                            <td>{formatDate(booking.date)}</td>
                            {token.role === 'admin' &&
                                <td>
                                    <Badge
                                        bg=""
                                        className={`p-2 py-1 ${booking.payment_status == '2' ? 'successcolorbg whitetext' : booking.payment_status == '1' ? 'warninglighterbg yellowtext' : booking.payment_status == '0' && 'errorlighterbg redtext'}`}
                                    >
                                        {booking.payment_status == '2' ? 'Paid' : booking.payment_status == '1' ? 'partial' : booking.payment_status == '0' && 'Unpaid'}
                                    </Badge>
                                </td>}
                            <td>
                                {booking?.passengers?.map((val, index) => (
                                    <p className='m-0 p-0'>{val.title}{' '}{val.firstname} {val.lastname}</p>
                                ))}
                            </td>
                            {token.role === 'admin' && <td>{booking.agent_name}</td>}
                            <td>{formatDate(booking.created_at)}</td>
                            <td>
                                <div className="d-grid text-center">
                                    <Badge
                                        bg=""
                                        className={`p-2 py-1 ${(booking.confirmed == 1 && booking.cancel_status == 0) ? 'successcolorbg whitetext' : (booking.confirmed == 0 && booking.cancel_status == 0) ? 'warninglighterbg yellowtext' : booking.cancel_status == 1 && 'errorlighterbg redtext'}`}
                                    >
                                        {booking.cancel_status == 1 ? 'Cancelled' : booking.confirmed == 1 ? 'Confirmed' : booking.confirmed == 0 && 'Hold'}
                                    </Badge>
                                    {(booking.cancel_status != 1 && booking?.cancel_at) ? <span className='fontweight700'>Ex. {formatDate(booking?.cancel_at)} at {pickTimeWithAMPM(booking?.cancel_at)}</span> : null}
                                </div>
                            </td>
                            <td>
                                <Dropdown className="" align="end" autoClose="outside">
                                    <Dropdown.Toggle className="p-2" variant="" id="dropdown-autoclose-outside">
                                        <img className="img-fluid" src={verticalicon} alt="icon" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Link to={`/dashboard/book/${booking?.id}`} className="dropdown-item">
                                            View
                                        </Link>
                                        {token.role === 'admin' ? <>
                                            <Link to={`#`} className="dropdown-item" onClick={(e) => {
                                                handalEdit(booking.id)
                                                setSelectedPnr({ date: booking?.date, pnr: booking.pnr });

                                            }}>
                                                Edit Booking
                                            </Link>
                                            {booking?.confirmed == 0 && booking?.cancel_status == 0 ? <Link to={`#`} className="dropdown-item" onClick={(e) => {
                                                handalTime(booking)
                                            }}>
                                                Extend H.Time
                                            </Link> : null}
                                            <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalCancel(booking.id)}>
                                                {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                                                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                    :
                                                    'Cancel Booking'
                                                }
                                            </Link>
                                            {booking?.confirmed == '1' ? <Link to={`#`} className="dropdown-item" onClick={(e) => handalPayment(booking)}>
                                                Add Payment
                                            </Link> : null}
                                            {booking?.confirmed != 1 && <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalShow(booking)}>
                                                {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                                                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                    :
                                                    'Confirm Booking'
                                                }
                                            </Link>}
                                            <Link to={`/dashboard/pnr/${booking.pnr}`} className="dropdown-item">
                                                Open full PNR
                                            </Link>

                                        </>
                                            :
                                            <>
                                                {(booking?.confirmed !== '1') && <Link to={`#`} className="dropdown-item" onClick={(e) => {
                                                    handalEdit(booking.id)
                                                    setSelectedPnr({ date: booking?.date, pnr: booking.pnr});

                                                }}>
                                                    Edit Booking
                                                </Link>}
                                                {booking?.confirmed !== '1' ? <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalCancel(booking.id)}>
                                                    {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                                                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                        :
                                                        'Cancel Booking'
                                                    }
                                                </Link> : null}
                                            </>}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {show &&
                <AddTicketPay editData={targetBooking} show={show} setShow={setShow} />
            }
            {showCon &&
                <ConfirmBooking show={showCon} setShow={setShowCon} path='booking-confirm' ConData={targetBooking} />
            }
            {showEdit &&
                <EditTicket id={targetBooking} show={showEdit} setShow={setShowEdit} selectedPnr={selectedPnr} />
            }
            {showTime &&
                <TimeUpdate show={showTime} setShow={setShowTime} selectedBooking={targetBooking} />
            }
            {confirmation.target == 'cancel' &&
                <ConfirmationPopup show={confirmation} setShow={setConfirmation} handleConfirm={handleConfirm} />
            }
        </>
    )
}

export default BookingsTRLoop;