import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, NavItem, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, calculateDuration, getBearerToken, toCapitalCase } from '../../functions';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import AddAgent from './AddAgent';
import { useAuth } from '../pages/auth/AuthProvider';
import toast from 'react-hot-toast';
import plusicon from "../../images/plusicon.svg";


const BookTicket = ({ show, setShow, selectedPnr, bookType }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addAgent, setAddAgent] = useState(false);
    const [available, setAvailable] = useState(false);
    const [error, setError] = useState('');
    const [booked, setBooked] = useState(false);
    const [customer, setCustomer] = useState('');
    // const Navigate = useNavigate()
    const [searchResults, setSearchResults] = useState([]);
    const [type, setType] = useState('')
    const [bookingType, setBookingType] = useState({
        adult: 'adult',
        Acount: 1,
        child: 'child',
        Ccount: 0,
        infant: 'infant',
        Icount: 0,
    });
    const [passengerDetails, setPassengerDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selected, setSelected] = useState('');
    const [bookingId, setBookingId] = useState('');
    const { token } = useAuth()
    const [data, setData] = useState({
        pnr: selectedPnr.pnr,
        agent: token.agent_id,
        walking: '',
        titles: [],
        firstname: [],
        lastname: [],
        dob: [],
        passport_no: [],
        passport_expiry_date: [],
        passport_img: [],
        visa_copy: [],
        confirmed: 1,
        total_amount: '',
        received_amount: '',
        remaining_amount: '',
        type: [],
        remarks: '',
        updated_at: selectedPnr.updated_at,
    });

    useEffect(() => {
        setData({
            ...data,
            titles: [],
            firstname: [],
            lastname: [],
            dob: [],
            passport_no: [],
            passport_expiry_date: [],
            passport_img: [],
            visa_copy: [],
            confirmed: 1,
            total_amount: '',
            received_amount: '',
            remaining_amount: '',
            type: [],
            remarks: '',
        })
        document.getElementById('myForm').reset();
        setAvailable(false)
        { bookingType?.Acount > 5 ? setError("You can select upto 5 Adult tickets only.") : setError('') }
    }, [bookingType])

    useEffect(() => {
        setData({
            ...data,
            total_amount: '',
            received_amount: '',
            remaining_amount: '',
        })
    }, [data.confirmed])


    const handleSearch = async (e) => {
        setSearchTerm(e.target.value)
        setLoading(true)
        searchTerm &&
            axios.post(`${API_BASE_URL}/searchagent`,
                {
                    searchquery: searchTerm
                }, {
                headers: {
                    'Authorization': `Bearer ${getBearerToken()}`
                }
            })
                .then(response => {
                    // Handle the response data
                    setSearchResults(response.data.data);
                    setLoading(false)
                }).catch(error => {
                    // Handle errors
                    setLoading(false)
                    console.error(error);
                });
    };
    const handleAgentSelection = (agent) => {
        setSearchResults([]);
        setSelected(true)
        setSearchTerm(agent?.name)
        setData({ ...data, agent: agent?.id })
    };


    useEffect(() => {
        // setCustomer('')
        setSearchTerm('');
        setSelected(false);
        // customer == 'Walking' && setData({ ...data, agent: 0, walking: 'walking' })
        // customer == 'Agent' && setData({ ...data, agent: 0, walking: '' })
    }, [customer])

    useEffect(() => {
        bookingType?.Icount <= bookingType?.Acount ? setError('') : setError('Infant can not more than adult.')
    }, [bookingType])


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)


        const fromdata = new FormData();
        fromdata.append('pnr', data.pnr)
        fromdata.append('agent', data.agent)
        fromdata.append('walking', data.walking)
        fromdata.append('updated_at', data.updated_at)
        Array.from(data.titles).forEach((item) => {
            fromdata.append('titles[]', item)
        });
        Array.from(data.firstname).forEach((item) => {
            fromdata.append('firstname[]', toCapitalCase(item))
        });
        Array.from(data.lastname).forEach((item) => {
            fromdata.append('lastname[]', toCapitalCase(item))
        });
        Array.from(data.dob).forEach((item) => {
            fromdata.append('dob[]', item)
        });
        Array.from(data.passport_no).forEach((item) => {
            fromdata.append('passport_no[]', item)
        });
        Array.from(data.passport_expiry_date).forEach((item) => {
            fromdata.append('passport_expiry_date[]', item)
        });
        Array.from(data.passport_img).forEach((item) => {
            fromdata.append('passport_img[]', item)
        });
        Array.from(data.visa_copy).forEach((item) => {
            fromdata.append('visa_copy[]', item)
        });
        // fromdata.append('lastname[]', data.lastname)
        // fromdata.append('dob[]', data.dob)
        // fromdata.append('passport_no[]', data.passport_no)
        // fromdata.append('passport_expiry_date[]', data.passport_expiry_date)
        fromdata.append('confirmed', data.confirmed)
        fromdata.append('total_amount', data.total_amount)
        fromdata.append('received_amount', data.received_amount)
        fromdata.append('remaining_amount', data.total_amount - data.received_amount)
        fromdata.append('remarks', data.remarks)
        // fromdata.append('type', data.type)
        Array.from(data.type).forEach((item) => {
            fromdata.append('type[]', item)
        });
        console.log("fromdata",fromdata);
        
        // Call API to submit data
        axios.post(`${API_BASE_URL}/add-booking`, fromdata, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        })
            .then(response => {
                setBookingId(response.data.data.id)
                toast.success(response.data.message)
                setToastMessage({ type: 'success', message: 'Ticket Booked successfully!', show: 'show' });
                setLoading(false)
                setBooked(true)
                // console.log(response);
            })
            // .then(result => {
            // Handle response from API
            // setToastMessage({ type: 'success', message: 'Ticket Booked successfully!', show: 'show' });
            // toast.success(result.data.message)
            // setLoading(false)
            // setBooked(true)
            // handleClose()
            // Navigate('/booking:10')
            // })
            .catch(error => {
                // Handle error
                toast.error(error.response.data.message)
                setToastMessage({ type: 'error', message: error });
                setLoading(false)
            });
    };

    const handleCheck = (e) => {
        e.preventDefault();
        setError('')
        setLoading(!loading)
        // console.log('bookingType', bookingType, selectedPnr);
        const fromdata1 = new FormData();
        fromdata1.append('adult', bookingType.Acount)
        fromdata1.append('child', bookingType.Ccount)
        fromdata1.append('infant', bookingType.Icount)
        fromdata1.append('pnr', selectedPnr.pnr)
        fromdata1.append('price', selectedPnr.price)
        // Call API to submit data
        axios.post(`${API_BASE_URL}/check-availbility`, fromdata1, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        })
            .then(response => {
                setLoading(false)
                setAvailable(response.data.data.status)
                if (!response.data.data.status) {
                    setError("Sorry! Please Refresh page and try again.")
                }
                // console.log(response);
            })
            .then(result => {
                // Handle response from API
                setToastMessage({ type: 'success', message: 'Ticket Booked successfully!', show: 'show' });
                setLoading(false)
                setAvailable(result.data.data.status)
                if (!response.data.data.status) {
                    setError("Sorry! Please Refresh page and try again.")
                }
                // console.log('test result');
            })
            .catch(error => {
                // Handle error
                setToastMessage({ type: 'error', message: error });
                setLoading(false)
                // console.log('test');
            });
    }

    const clearImage = (field, index) => {
        // console.log("asdf", field, index);

        setData((prevData) => ({
            ...prevData,
            [field]: {
                ...prevData[field],
                [index]: null, // Set the image to null
            },
        }));
    };

    // const handleSelectChange = (event, field, index) => {

    //     if (index < parseInt(bookingType.Acount)) {
    //         setType('adult')
    //         console.log('adult', type);
    //     }
    //     if (index >= parseInt(bookingType.Acount) && index < parseInt(bookingType.Acount) + parseInt(bookingType.Ccount)) {
    //         setType('child')
    //         const durationInYears = calculateDuration(data.dob[index], selectedPnr.date);
    //         { durationInYears > 12 && setError('DOB issue, Child no more than 12 years') }
    //         console.log('child', durationInYears, type);
    //     }
    //     if (index >= parseInt(bookingType.Acount) + parseInt(bookingType.Ccount)) {
    //         setType('infant')
    //         const durationInYears = calculateDuration(data.dob[index], selectedPnr.date);
    //         { durationInYears > 2 && setError('DOB issue, Infant no more that 2 years, ') }
    //         console.log('infant', durationInYears, type);
    //     }
    //     console.log("outside if", type);
    //     setData((prevData) => {
    //         console.log("In update", type);
    //         const updatedData = { ...prevData };
    //         updatedData.type[index] = type;
    //         return updatedData;
    //     });
    //     setData((prevData) => ({
    //         ...prevData,
    //         [field]: Object.assign([], prevData[field], { [index]: event.target.value }),
    //     }));
    //     console.log("after update but in func", type);

    // };

    const handleSelectChange = (event, field, index) => {

        let value = null;
        if (field === "passport_img" || field === "visa_copy") {
            const file = event.target.files[0]; // Get the selected file
            if (file) {
                const maxSize = 2 * 1024 * 1024; // 2MB limit
                if (file.size > maxSize) {
                    alert("File size should be less than 2MB");
                    event.target.value = null; // Reset the input field
                    return; // Stop execution if file is too large
                }
                value = file; // Assign the valid file
            }
        }

        if (index < parseInt(bookingType.Acount)) {
            setType('adult');
            setData((prevData) => {
                const updatedData = { ...prevData };
                updatedData.type[index] = 'adult';
                return updatedData;
            });
        } else if (index >= parseInt(bookingType.Acount) && index < parseInt(bookingType.Acount) + parseInt(bookingType.Ccount)) {
            setType('child');
            const durationInYears = calculateDuration(event.target.value, selectedPnr.date);
            if (durationInYears > 11.931798736455917) {
                setError(`Selected AGE is ${durationInYears.toFixed(2)}, Child shouldn't be more than 12 years`);
            } else {
                setError('');
            }
            setData((prevData) => {
                const updatedData = { ...prevData };
                updatedData.type[index] = 'child';
                return updatedData;
            });
        } else {
            setType('infant');
            const durationInYears = calculateDuration(event.target.value, selectedPnr.date);
            if (durationInYears > 1.931798736455917) {
                setError(`Selected AGE is ${durationInYears.toFixed(2)}, But Infant shouldn't be more that 2 years`);
            } else {
                setError('');
            }
            setData((prevData) => {
                const updatedData = { ...prevData };
                updatedData.type[index] = 'infant';
                return updatedData;
            });
        }
        if (field === "passport_img" || field === "visa_copy") {
            setData((prevData) => ({
                ...prevData,
                [field]: Object.assign([], prevData[field], { [index]: value }),
            }));
        } else {
            setData((prevData) => ({
                ...prevData,
                [field]: Object.assign([], prevData[field], { [index]: event.target.value }),
            }));
        }
    };
    const handleCheckboxChange = (e) => {
        // If the checkbox is checked, set the value to 'Agent', otherwise set it to an empty string
        const newValue = e.target.checked ? 'Agent' : '';
        setCustomer(newValue);
        setData({ ...data, agent: e.target.checked ? 0 : token?.agent_id })
    };

    // console.log("BookTicket data >>>>>>>",data);

    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="xl" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form className={'AllForm'} onSubmit={handleSubmit} id="myForm">
                    <Modal.Header closeButton className="d-flex px-4 align-items-center">
                        <Modal.Title id="contained-modal-title-vcenter">{booked ? 'Ticket Booked!' : 'Book Ticket'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        {/*  */}
                        {booked ?
                            <Row className={'my-4'}>
                                <Col className={'mb-4 mb-lg-0'} xl={'6'} lg={'6'}>
                                    <Link target='_blank' to={`https://dnst.dhilluandsahi.com/api/booking-pdf/${bookingId}`} className='fontweight700 greenbg border-0 fontsize14 whitetext px-3 py-2 rounded'>Download Booking</Link>
                                    <Link className='ms-3' to={`/dashboard/book/${bookingId}`}>View Booking</Link>
                                </Col>
                            </Row>
                            :
                            <div className='mb-4'>
                                {/* <Form.Check type={'radio'} value={'Walking'} label={'Walking'} name={'radio'} id={'radioWalking'} onChange={(e) => setCustomer(e.target.value)} /> */}
                                {token.role === 'admin' &&
                                    <div className="d-flex align-items-center mb-3">
                                        <Form.Check
                                            type={'checkbox'}
                                            label={'Book for Agent'}
                                            className={'me-4'}
                                            id={'radioAgent'}
                                            checked={customer === 'Agent'} // Check if the customer value is 'Agent' to set the checkbox as checked
                                            onChange={handleCheckboxChange}
                                        />
                                        {customer == 'Agent' &&
                                            <div className='w-100'>
                                                <div className="text-end">
                                                    <Link to={'#'} className='greentext' onClick={() => setAddAgent(true)}>Add new Agent</Link>
                                                </div>
                                                <InputGroup className="form-group">
                                                    <Form.Label>Agent Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search Agent by name"
                                                        aria-label="Username"
                                                        aria-describedby="basic-addon1"
                                                        onChange={handleSearch}
                                                        readOnly={selected}
                                                        value={searchTerm}
                                                        required
                                                    />
                                                    {selected &&
                                                        <Button
                                                            type={''}
                                                            variant={''}
                                                            className={'fontweight700 greenbg fontsize14 whitetext'}
                                                            onClick={() => {
                                                                setSearchTerm('');
                                                                setSelected(false);
                                                                setData({ ...data, agent: 0 })
                                                                setError(null)
                                                            }}
                                                        >
                                                            Select new Agent
                                                        </Button>
                                                    }
                                                </InputGroup>
                                                {searchTerm && !selected && (
                                                    <div className='searchdropdow mt-1'>
                                                        {loading ? (
                                                            <div className={'mt-3 w-100 text-center'}>
                                                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {searchResults.length > 0
                                                                    ? searchResults.map((agent) => (
                                                                        <NavItem
                                                                            className=''
                                                                            key={agent.id}
                                                                            onClick={() => handleAgentSelection(agent)}
                                                                        >
                                                                            <p className='p-3'>{agent.name}</p>
                                                                        </NavItem>
                                                                    ))
                                                                    : !selected && 'No Agent Found'}
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>}
                                <div className="d-flex align-items-center">
                                    <Form.Group className="me-4 form-group" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='m-0'>Adults</Form.Label>
                                        <Form.Control type="text" name={''} id={''} placeholder={'First Name/Given Name'}
                                            onChange={(e) => setBookingType({ ...bookingType, Acount: e.target.value === '' ? 0 : parseInt(e.target.value) })}
                                            value={bookingType.Acount}
                                        ></Form.Control>
                                        {/* <Form.Select aria-label="Default select example"
                                            as="select"
                                            value={bookingType.Acount}
                                            onChange={(e) => setBookingType({ ...bookingType, Acount: e.target.value })}
                                        >
                                            <option className='blacktext' value={1}>1</option>
                                            <option className='blacktext' value={2}>2</option>
                                            <option className='blacktext' value={3}>3</option>
                                        </Form.Select> */}
                                    </Form.Group>
                                    <Form.Group className="me-4 form-group" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='m-0'>Childs</Form.Label>
                                        <Form.Control type="text" name={''} id={''} placeholder={'First Name/Given Name'}
                                            onChange={(e) => setBookingType({ ...bookingType, Ccount: e.target.value === '' ? 0 : parseInt(e.target.value) })}
                                            value={bookingType.Ccount}
                                        ></Form.Control>
                                        {/* <Form.Select aria-label="Default select example"
                                            as="select"
                                            value={bookingType.Ccount}
                                            onChange={(e) => setBookingType({ ...bookingType, Ccount: e.target.value })}
                                        >
                                            <option className='blacktext' value={0}>0</option>
                                            <option className='blacktext' value={1}>1</option>
                                            <option className='blacktext' value={2}>2</option>
                                            <option className='blacktext' value={3}>3</option>
                                        </Form.Select> */}
                                    </Form.Group>
                                    <Form.Group className="me-4 form-group" controlId="exampleForm.ControlInput1">
                                        <Form.Label className='m-0'>Infants</Form.Label>
                                        <Form.Control type="text" name={''} id={''} placeholder={'First Name/Given Name'}
                                            value={bookingType.Icount}
                                            onChange={(e) => setBookingType({ ...bookingType, Icount: e.target.value === '' ? 0 : parseInt(e.target.value) })}
                                        ></Form.Control>
                                        {/* <Form.Select aria-label="Default select example"
                                            as="select"
                                            value={bookingType.Icount}
                                            onChange={(e) => setBookingType({ ...bookingType, Icount: e.target.value })}
                                        >
                                            <option className='blacktext' value={0}>0</option>
                                            <option className='blacktext' value={1}>1</option>
                                            <option className='blacktext' value={2}>2</option>
                                            <option className='blacktext' value={3}>3</option>
                                        </Form.Select> */}
                                    </Form.Group>
                                    <Button variant="" disabled={bookingType.Acount > 5 || error} className="w-100px greenbg whitetext fontsize14 fontweight700 me-3" onClick={handleCheck}>
                                        {loading ?
                                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                            : 'Start Booking'
                                        }
                                    </Button>
                                </div>

                            </div>
                        }
                        {(!booked && available) &&
                            <>
                                {/*  */}
                                {[...Array(parseInt(bookingType.Acount) + parseInt(bookingType.Ccount) + parseInt(bookingType.Icount))].map((_, index) => (
                                    <Row className={'my-4'} key={index}>
                                        <p className='fontsize16 d-flex align-items-center'>{index + 1} : Passengers Details -
                                            {index < parseInt(bookingType.Acount) &&
                                                <h4 className='fontsize18'>Adult</h4>}
                                            {index >= parseInt(bookingType.Acount) && index < parseInt(bookingType.Acount) + parseInt(bookingType.Ccount) &&
                                                <h4 className='fontsize18' >Child </h4>}
                                            {index >= parseInt(bookingType.Acount) + parseInt(bookingType.Ccount) &&
                                                <h4 className='fontsize18' >Infant</h4>}
                                        </p>
                                        <Col className={'mb-4 mb-lg-0'} xl={'2'} lg={'2'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Title <span className='redtext'>*</span></Form.Label>
                                                <Form.Select aria-label="Default select example"
                                                    as="select"
                                                    onChange={(event) => handleSelectChange(event, "titles", index)}
                                                    required
                                                >
                                                    <option className='blacktext' value={''}>Select title </option>
                                                    {index < parseInt(bookingType.Acount) &&
                                                        <>
                                                            <option className='blacktext' value={'Mr'}>Mr.</option>
                                                            <option className='blacktext' value={'Mrs'}>Mrs.</option>
                                                            <option className='blacktext' value={'Ms'}>Ms.</option>
                                                        </>

                                                    }
                                                    {index >= parseInt(bookingType.Acount) && index < parseInt(bookingType.Acount) + parseInt(bookingType.Ccount) &&
                                                        <>
                                                            <option className='blacktext' value={'Mstr'}>Mstr.</option>
                                                            <option className='blacktext' value={'Miss'}>Miss.</option>
                                                        </>

                                                    }
                                                    {index >= parseInt(bookingType.Acount) + parseInt(bookingType.Ccount) &&
                                                        <>
                                                            <option className='blacktext' value={'Inf'}>Inf.</option>
                                                        </>
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>First Name/Given Name <span className='redtext'>*</span></Form.Label>
                                                <Form.Control type="text" name={''} id={''} placeholder={'First Name/Given Name'} required
                                                    // onChange={(e) => setData({ ...data, firstname: e.target.value })}
                                                    onChange={(event) => handleSelectChange(event, "firstname", index)}

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Last Name/Surname <span className='redtext'>*</span></Form.Label>
                                                <Form.Control type="text" name={''} id={''} placeholder={'Last Name/Surname'} required
                                                    // onChange={(e) => setData({ ...data, lastname: e.target.value })} 
                                                    onChange={(event) => handleSelectChange(event, "lastname", index)}

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'2'} lg={'2'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Date of Birth <span className='redtext'>*</span></Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    name=""
                                                    id=""
                                                    placeholder="Date of Birth"
                                                    required
                                                    max={new Date().toISOString().split("T")[0]}
                                                    onChange={(event) => handleSelectChange(event, "dob", index)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Passport Number <span className='redtext'>*</span></Form.Label>
                                                <Form.Control type="text" name={''} id={''} placeholder={'Passport Number'} required
                                                    // onChange={(e) => setData({ ...data, passport_no: e.target.value })}
                                                    onChange={(event) => handleSelectChange(event, "passport_no", index)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Passport Expiry Date <span className='redtext'>*</span></Form.Label>
                                                <Form.Control type="date" name={''} id={''} placeholder={'Passport Expiry Date'} required
                                                    min={new Date().toISOString().split("T")[0]}
                                                    // onChange={(e) => setData({ ...data, passport_expiry_date: e.target.value })}
                                                    onChange={(event) => handleSelectChange(event, "passport_expiry_date", index)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group d-flex" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Passport Photo/pdf <span className='redtext'>*</span></Form.Label>
                                                <div className='imageuploader me-2'>
                                                    <Form.Control
                                                        required={true} // Mark as required only when adding a new agent
                                                        type="file"
                                                        accept="image/*,application/pdf" 
                                                        name={''}
                                                        id={''}
                                                        placeholder={'Passport Photo/pdf'}
                                                        onChange={(event) => handleSelectChange(event, "passport_img", index)}

                                                    />
                                                    {/* <img src={plusicon} alt="Agent" width="100" height="100" /> */}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group d-flex" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Visa copy/pdf</Form.Label>
                                                <div className='imageuploader me-2'>
                                                    <Form.Control
                                                        required={false} // Mark as required only when adding a new agent
                                                        type="file"
                                                        accept="image/*,application/pdf" 
                                                        name={''}
                                                        id={''}
                                                        placeholder={'Visa copy/pdf'}
                                                        onChange={(event) => handleSelectChange(event, "visa_copy", index)}

                                                    />
                                                    {/* <img src={plusicon} alt="Agent" width="100" height="100" /> */}
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                ))}
                                {/*  */}
                                {/* <Form.Check
                                    type="radio"
                                    label="Confirmed"
                                    checked={data?.confirmed === 1}
                                    name="confirmradio"
                                    id="radioConfirm"
                                    onClick={() => setData({ ...data, confirmed: data?.confirmed === 1 ? 0 : 1 })}
                                    onChange={() => setData({ ...data, confirmed: data?.confirmed === 1 ? 0 : 1 })}
                                /> */}
                                {/* {data.confirmed === 1 &&
                                    <Row className={'mt-4'}>
                                        <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Total Amount</Form.Label>
                                                <Form.Control type="number" name={''} id={''} placeholder={'Amount'}
                                                    onChange={(e) => setData({ ...data, total_amount: e.target.value })}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Received</Form.Label>
                                                <Form.Control type="number" name={''} id={''} placeholder={'Received'}
                                                    onChange={(e) => setData({ ...data, received_amount: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Remaining (auto)</Form.Label>
                                                <Form.Control type="text" readOnly name={''} id={''} placeholder={'Remaining'} value={data.total_amount - data.received_amount}
                                                // onChange={(e) => setData({ ...data, remaining_amount: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                } */}
                            </>
                        }
                        {(!booked && available) && <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Remarks</Form.Label>
                            <Form.Control type="text" name={''} id={''} placeholder={'Remarks....'}
                                onChange={(e) => setData({ ...data, remarks: e.target.value })}
                            />
                        </Form.Group>}

                        <p className='redtext mt-2'>{error}</p>{error &&
                            <Button variant="" className="w-100px redbg whitetext fontsize14 fontweight700 me-3" onClick={() => window.location.reload()}>Refresh</Button>
                        }
                    </Modal.Body>
                    <Modal.Footer className="">
                        <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                        {(!booked && available) &&
                            <Button variant="" disabled={loading || error} type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg">
                                {loading ?
                                    <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                    : 'Book'
                                }
                            </Button>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
            {addAgent &&
                <AddAgent show={addAgent} setShow={setAddAgent} path='booking' />
            }
        </>
    )
}

export default BookTicket;