import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Layout from './Layout';
import Dashboard from './components/pages/dashboard-compo/Dashboard';
import Booking from './components/pages/booking-compo/Booking';
import Payments from './components/pages/payments-compo/Payments';
import Airlines from './components/pages/airlines-compo/Airlines';
import Sectors from './components/pages/sectors-compo/Sectors';
import Groups from './components/pages/groups-compo/Groups';
import AddNewGroup from './components/pages/groups-compo/AddNewGroup';
import Agents from './components/pages/agents-compo/Agents';
import MySectors from './components/pages/mysectors-compo/MySectors';
import GroupsHistory from './components/pages/groupshistory-compo/GroupsHistory';
import Settings from './components/pages/settings-compo/Settings';
import SignIn from './components/pages/SignIn';
import Forgot from './components/pages/Forgot';
import SignUp from './components/pages/SignUp';
import AuthHOC from './components/AuthHOC';
import PnrDetailPage from './components/pages/pnr-compo/PnrDetailPage';
import MySectorDetailPage from './components/pages/mysectors-compo/MySectorDetailPage';
import BookingDetail from './components/pages/booking-compo/BookingDetail';
import { SearchData } from './components/pages/search/SearchData';
import Home from './components/PublicPages/Home';
import PublicLayout from './PublicLayout';
import Tickets from './components/PublicPages/Tickets';
import AboutUs from './components/PublicPages/AboutUs';
import ContactUs from './components/PublicPages/ContactUs';
import BankDetails from './components/PublicPages/BankDetails';
import WebBasedAirlines from './components/PublicPages/WebBasedAirlines';
import ThankYou from './components/pages/ThankYou';
import AllUsers from './components/pages/user-management/AllUsers';
import toast, { Toaster } from 'react-hot-toast';
import Accounts from './components/pages/User/Accounts/Accounts';
import AgentProfile from './components/pages/User/Profile/AgentProfile';
import Notifications from './components/pages/Notifications/Notifications';
import { pusher } from './pusherConfig';
import { useAuth } from './components/pages/auth/AuthProvider';
import { Button } from 'react-bootstrap';
import EditGroup from './components/pages/groups-compo/EditGroup';
import AgentsBalance from './components/pages/payments-compo/AgentsBalance';
import { EmailPage } from './components/pages/email-compo/EmailPage';
import PageNotFound from './components/pages/PageNotFound';
import SitemapGenerator from './sitemap-generator';
import PendingBooking from './components/pages/booking-compo/PendingBooking';
import Updates from './components/PublicPages/Updates';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // initial state is false for not authenticated
  const [notification, setNotification] = useState(''); // initial state is false for not authenticated
  const [chatAdded, setChatAdded] = useState(false); // initial state is false for not authenticated
  const { token } = useAuth();
  // useEffect(() => {
  // Enable pusher logging - don't include this in production
  // Pusher.logToConsole = true;

  // const pusher = new Pusher('58b1a736b7d1c40a8af9', {
  //   cluster: 'ap1',
  //   // add any other configurations you need
  // });

  const channel = token && pusher.subscribe(`userchannel-${token.user_id}`);
  // console.log("channel ===>",channel);
  channel && channel.bind(`userevent-${token.user_id}`, function (data) {
    // console.log('Received event with data:', data);

    toast.custom((t) => (
      <div
        className={`${t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto d-flex ring-1 ring-black ring-opacity-5 rounded p-2`}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content d-flex flex-row">
            <Link to={'/dashboard/bookings'} className='blacktext'>
              <div className="modal-body">
                <div className="d-flex align-items-start">
                  <img
                    className="rounded-circle me-3"
                    src={data?.data?.data?.image}
                    alt=""
                    width="50"
                    height="50"
                  />
                  <div className="flex-grow-1">
                    <p className="text-sm font-medium text-gray-900 m-0 p-0">
                      {data?.data?.data?.name || 'N/A'}
                    </p>
                    <p className="mt-1 text-sm text-gray-500 m-0 p-0">
                      {data?.data?.data?.message || 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <div className="modal-footer border-0 ms-4">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="btn btn-outline-primary rounded"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

    ), {
      position: 'bottom-left',
    })
    // Handle the received data as needed
  });

  // Clean up subscriptions and connections on component unmount
  // return () => {
  //   channel.unbind(); // Unbind all callbacks on this channel
  //   pusher.unsubscribe(`userchannel-${UserID}`); // Unsubscribe from the channel
  //   pusher.disconnect(); // Disconnect from Pusher service
  // };
  // }, []); // Empty dependency array ensures this effect runs once after initial render

  const handleTopUpdate = (newTopValue) => {
    // Update the 'top' property of the element
    const container = document.getElementById('hubspot-messages-iframe-container');
    container.style.setProperty('left', `${newTopValue}px`, 'important');
  };

  function handleChatBoxAdded(mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          // Check if the added node has the ID 'hubspot-messages-iframe-container'
          if (node.id === 'hubspot-messages-iframe-container') {
            // The chat box has been added to the DOM, do something here
            // console.log('Chat box added to the DOM');
            setChatAdded(true)
            // You can also stop observing further mutations if needed
            observer.disconnect();
          }
        });
      }
    }
  }

  // Create a new MutationObserver
  // const observer = new MutationObserver(handleChatBoxAdded);

  // Start observing changes to the DOM
  // observer.observe(document.body, { childList: true, subtree: true });

  // useEffect(() => {
  //   const container = document.getElementById('hubspot-messages-iframe-container');
  //   if (container) {
  //     // Create a new div element
  //     const newSelect = document.createElement('select');
  //     newSelect.classList.add('cstm-select');
  //     newSelect.id = 'mySelect';

  //     // Create and append option elements to the select
  //     const option1 = document.createElement('option');
  //     option1.value = 'option1';
  //     option1.textContent = 'Bottom';
  //     newSelect.appendChild(option1);

  //     const option2 = document.createElement('option');
  //     option2.value = 'option2';
  //     option2.textContent = 'Midle';
  //     newSelect.appendChild(option2);

  //     const option3 = document.createElement('option');
  //     option3.value = 'option3';
  //     option3.textContent = 'Top';
  //     newSelect.appendChild(option3);

  //     // Add the new select to the container
  //     const container = document.getElementById('hubspot-messages-iframe-container');
  //     container.appendChild(newSelect);
  //   }
  // }, [chatAdded])

  // useEffect(() => {
  //   let isLogin = localStorage.getItem('token');
  //   if (isLogin) {
  //     const container = document.getElementById('hubspot-messages-iframe-container');
  //     if (container) {
  //       handleTopUpdate(20)
  //     }
  //   }
  // }, [chatAdded]);

  // console.log("in app");


  // const [token1, setToken1] = useState(JSON.parse(localStorage.getItem('token')) || null);
  // useEffect(() => {
  //   setToken1({ ...token1, change_password: true })
  // }, [token]);
  // localStorage.setItem('token', JSON.stringify(token1));

  const currentVersion = "1.0"; // Isko update karein jab new changes aayein
  if (localStorage.getItem("siteVersion") !== currentVersion) {
    localStorage.setItem("siteVersion", currentVersion);
    location.reload(true); // Hard refresh
  }
  return (
    <>
      {/* <Button onClick={() => handleTopUpdate(20)} style={{ zIndex: '99999', position: 'relative' }}>test</Button> */}
      <Toaster />
      <Routes>
        <Route path='/' element={<PublicLayout />}>
          <Route index element={<Home />} />
          <Route path='download-sitemap' element={<SitemapGenerator />} />
          <Route path='/search/:dip/:arriv/:date' element={<Tickets />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/bankaccounts" element={<BankDetails />} />
          {/* <Route path="/wba" element={<WebBasedAirlines />} /> */}
        </Route>

        <Route path="/signin" element={<SignIn setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/dashboard" element={<AuthHOC Component={Layout} />}>
          <Route index element={<Dashboard />} />
          <Route path="bookings" element={<Booking />} />
          <Route path="pendingbookings" element={<PendingBooking />} />
          <Route path="cancelbookings" element={<Booking />} />
          <Route path="book/:id" element={<BookingDetail />} />
          <Route path="payments" element={<Payments />} />
          <Route path="airlines" element={<Airlines />} />
          <Route path="sectors" element={<Sectors />} />
          <Route path="groups" element={<Groups />} />
          <Route path="addnewgroup" element={<AddNewGroup />} />
          <Route path="update-group/:id" element={<EditGroup />} />
          <Route path="agents" element={<Agents />} />
          <Route path="my-sectors" element={<MySectors />} />
          <Route path="groups-history" element={<GroupsHistory />} />
          <Route path="settings" element={<Settings />} />
          <Route path="users" element={<AllUsers />} />
          <Route path='pnr/:slug' element={<PnrDetailPage />} />
          <Route path='booking/:id/:name' element={<MySectorDetailPage />} />
          <Route path='search/:query' element={<SearchData />} />
          <Route path='accounts' element={<Accounts />} />
          <Route path='agents-blnc' element={<AgentsBalance />} />
          <Route path='my-profile' element={<AgentProfile />} />
          <Route path='notifications' element={<Notifications />} />
          <Route path='emails' element={<EmailPage />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
