import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Row, Col, Form, Modal, InputGroup, Table } from 'react-bootstrap';
import BookTicket from '../../Modals/BookTicket';
import { formatDate } from '../../../functions';

import planeicon from "../../../images/plane100.png";

const BookingCardLoop = ({ props }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedPnr, setSelectedPnr] = useState({ date: '', pnr: '', price: 0 });
  const [uniqueImageSources, setUniqueImageSources] = useState([]);

  // console.log("adfa>>>>>>>", props);
  return (
    <>
      {props?.map((val, index) => {
        return (
          <Card className={'mb-4'}>
            <Card.Header className={'px-1 px-lg-1 py-1 py-lg-1 d-flex align-items-center'}>
              <div className={'d-flex align-items-center'}>
                <Link className={`iconlogo1`}>
                  <img className="img-fluid h30" src={planeicon} alt="icon" />
                  {/* <img className="img-fluid h40" src={val.airline_logo} alt="icon" /> */}
                </Link>
                {/* {val?.air_line_logos?.map((airline, i) => {
                  return (<Link className={`iconlogo1 O${i + 1}`}><img className={'img-fluid'} src={airline.logo} alt={'icon'} /></Link>)
                })
                } */}
              </div>
              <h5 className={'ms-2 titlehead'}>{val.sector}</h5>
            </Card.Header>
            {val?.tickets?.map((pnr, i) => {
              return (
                <Card.Body key={i} className='p-1'>
                  <Table className={'mb-0 allView colored'} responsive={'sm'}>
                    <tbody>
                      <tr>
                        <td className='first-td'><Link to={`/dashboard/pnr/${pnr?.pnr}`} className={'text-blue text-decoration-none fontsize14 fontweight600'}>{pnr?.pnr}</Link></td>
                        <td>
                          {pnr?.subpart?.map((subpart, j) => (
                            <>
                              {formatDate(subpart?.departure_date)}<br />
                            </>
                          ))}
                        </td>
                        <td>
                          {pnr?.subpart?.map((subpart, j) => (
                            <>
                              {subpart?.departure_sector_name}<br />
                            </>
                          ))}
                        </td>
                        <td><div className='airlinelogo'><img className={'img-fluid'} src={pnr?.air_line_logo} alt={'icon'} /></div></td>
                        <td>Sold: {pnr.sold}</td>
                        <td>Remaining: {pnr.remaining}</td>
                        <td align={'end'}><Button
                          value={pnr.pnr}
                          onClick={(e) => {
                            setSelectedPnr({ date: pnr?.date, pnr: e.target.value, price: pnr.price,updated_at: pnr.updated_at });
                            setModalShow(true);
                          }}
                          className="bluebg px-2 py-1 btn text-white" >
                          Book
                        </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              )
            })}
          </Card>
        )
      })}
      {modalShow &&
        <BookTicket show={modalShow} setShow={setModalShow} selectedPnr={selectedPnr} />
      }
    </>
  )
}

export default BookingCardLoop;