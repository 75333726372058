import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, NavItem, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, API_BASE_URL_Main, calculateDuration, getBearerToken } from '../../functions';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import AddAgent from './AddAgent';
import { useAuth } from '../pages/auth/AuthProvider';
import toast from 'react-hot-toast';

const EditTicket = ({ show, setShow, selectedPnr, id }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addAgent, setAddAgent] = useState(false);
    const [error, setError] = useState('');
    const [booked, setBooked] = useState(false);
    const [customer, setCustomer] = useState('');
    const Navigate = useNavigate()
    const [searchResults, setSearchResults] = useState([]);
    const [type, setType] = useState('')
    const [bookingType, setBookingType] = useState({
        adult: 'adult',
        Acount: 0,
        child: 'child',
        Ccount: 0,
        infant: 'infant',
        Icount: 0,
    });
    const [passengerDetails, setPassengerDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selected, setSelected] = useState('');
    const [bookingId, setBookingId] = useState('');
    const [data, setData] = useState({
        pnr: !id && selectedPnr.pnr,
        agent: 0,
        walking: '',
        firstname: [],
        title: [],
        lastname: [],
        dob: [],
        passport_no: [],
        passport_expiry_date: [],
        confirmed: 1,
        total_amount: '',
        received_amount: '',
        remaining_amount: '',
        type: [],
        passport_img: [],
        visa_copy: [],
    });
    const { token } = useAuth();
    const [bookingSub, setBookingSub] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState({}); // Store selected file names
    const [errors, setErrors] = useState({}); // Store validation errors

    useEffect(() => {
        const fromdata1 = new FormData();
        fromdata1.append('booking_id', id)
        axios
            .post(`${API_BASE_URL}/${'booking-edit-detail'}`, fromdata1, {
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
            })
            .then((response) => {
                setData(response?.data?.data?.data)
                setBookingSub(response?.data?.data?.data.booking_sub)
                setLoading(false)
            })
            .then((result) => {
                toast.error(result.response.data.message)
                setLoading(false)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false)
            });
    }, [id])

    // useEffect(() => {
    //     setData({
    //         ...data,
    //         firstname: [],
    //         lastname: [],
    //         dob: [],
    //         passport_no: [],
    //         passport_expiry_date: [],
    //         confirmed: 1,
    //         total_amount: '',
    //         received_amount: '',
    //         remaining_amount: '',
    //         type: [],
    //     })
    //     document.getElementById('myForm').reset();
    // }, [bookingType])

    // useEffect(() => {
    //     setData({
    //         ...data,
    //         total_amount: '',
    //         received_amount: '',
    //         remaining_amount: '',
    //     })
    // }, [data.confirmed])

    const handleSearch = async (e) => {
        setSearchTerm(e.target.value)
        setLoading(true)
        searchTerm &&
            axios.post(`${API_BASE_URL}/searchagent`,
                {
                    searchquery: searchTerm
                }, {
                headers: {
                    'Authorization': `Bearer ${getBearerToken()}`
                }
            })
                .then(response => {
                    // Handle the response data
                    setSearchResults(response.data.data);
                    setLoading(false)
                }).catch(error => {
                    // Handle errors
                    setLoading(false)
                    console.error(error);
                });
    };
    const handleAgentSelection = (agent) => {
        setSearchResults([]);
        setSelected(true)
        setSearchTerm(agent?.name)
        // setData({ ...data, agent: agent?.id })
    };


    useEffect(() => {
        setSearchTerm('');
        setSelected(false);
        // customer == 'Walking' && setData({ ...data, agent: 0, walking: 'walking' })
        // customer == 'Agent' && setData({ ...data, agent: 0, walking: '' })
    }, [customer])



    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        let newErrors = {};
        let hasError = false;

        data.booking_sub.forEach((item, index) => {
            if (!item.passport_img && !selectedFiles[index]) {
                newErrors[index] = "Passport image is required";
                hasError = true;
            }
        });

        if (hasError) {
            setErrors(newErrors);
            alert("Please select required passport images before submitting.");
            return;
        }
        const transformedData = {
            booking_id: data.id,
            dob: data.booking_sub.map(item => item.dob),
            firstname: data.booking_sub.map(item => item.firstname),
            lastname: data.booking_sub.map(item => item.lastname),
            passport_expiry_date: data.booking_sub.map(item => item.passport_expiry_date),
            passport_no: data.booking_sub.map(item => item.passport_no),
            title: data.booking_sub.map(item => item.title),
            type: data.booking_sub.map(item => item.type),
            passport_img: data.booking_sub.map(item => item.passport_img),
            visa_copy: data.booking_sub.map(item => item.visa_copy),
        };
        // console.log('transformedData', transformedData);
        const fromdata = new FormData();
        fromdata.append('booking_id', transformedData.booking_id)
        Array.from(transformedData.title).forEach((item) => {
            fromdata.append('titles[]', item)
        });
        Array.from(transformedData.firstname).forEach((item) => {
            fromdata.append('firstname[]', item)
        });
        Array.from(transformedData.lastname).forEach((item) => {
            fromdata.append('lastname[]', item)
        });
        Array.from(transformedData.dob).forEach((item) => {
            fromdata.append('dob[]', item)
        });
        Array.from(transformedData.passport_no).forEach((item) => {
            fromdata.append('passport_no[]', item)
        });
        Array.from(transformedData.passport_expiry_date).forEach((item) => {
            fromdata.append('passport_expiry_date[]', item)
        });
        Array.from(transformedData.type).forEach((item) => {
            fromdata.append('type[]', item)
        });
        Array.from(transformedData.title).forEach((item) => {
            fromdata.append('title[]', item)
        });
        Array.from(transformedData.passport_img).forEach((item) => {
            fromdata.append('passport_img[]', item)
        });
        Array.from(transformedData.visa_copy).forEach((item) => {
            fromdata.append('visa_copy[]', item)
        });
        // Call API to submit data
        axios.post(`${API_BASE_URL}/booking-update`, fromdata, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        })
            .then(response => {
                toast.success(response.data.message);
            })
            .then(result => {
                // Handle response from API
                toast.success(result.data.message);
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                toast(response.data.message);
            });
    };


    const handleSelectChange = (event, field, index) => {
        setData((prevData) => {
            const updatedBookingSub = [...prevData.booking_sub];
            updatedBookingSub[index] = {
                ...updatedBookingSub[index],
                [field]: event.target.value,
            };

            return {
                ...prevData,
                booking_sub: updatedBookingSub,
            };
        });
    };
    const handleFileSelectChange = (event, field, index) => {
        let value = null;

        if (field === "passport_img" || field === "visa_copy") {
            const file = event.target.files[0]; // Get the selected file
            if (file) {
                const maxSize = 2 * 1024 * 1024; // 2MB limit
                if (file.size > maxSize) {
                    alert("File size should be less than 2MB");
                    event.target.value = ""; // Reset the input field
                    return;
                }
                value = file; // Store the File object

                // Store selected file name for display
                setSelectedFiles((prev) => ({
                    ...prev,
                    [`${field}_${index}`]: file.name, // Store file name separately for each field & index
                }));

                // Remove error if file is selected
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`${field}_${index}`]: null, // Ensure the error is removed per field & index
                }));
            }
        }

        setData((prevData) => {
            const updatedBookingSub = [...prevData.booking_sub];

            // Ensure the specific index exists
            if (!updatedBookingSub[index]) {
                updatedBookingSub[index] = {};
            }

            updatedBookingSub[index] = {
                ...updatedBookingSub[index],
                [field]: value, // Update only the specific field
            };

            return {
                ...prevData,
                booking_sub: updatedBookingSub,
            };
        });
    };



    useEffect(() => {
        if (!booked && data?.booking_sub?.length) {
            data?.booking_sub.forEach((booking) => {
                const { type } = booking;
                setBookingType(prevState => {
                    if (type === 'adult') {
                        return { ...prevState, Acount: prevState.Acount + 1 };
                    } else if (type === 'child') {
                        return { ...prevState, Ccount: prevState.Ccount + 1 };
                    } else if (type === 'infant') {
                        return { ...prevState, Icount: prevState.Icount + 1 };
                    }
                    return prevState; // Return unchanged state if type doesn't match
                });
            });
            setBooked(true)
        }

    }, [data]);

    console.log('data', data, loading);
    // console.log('=====>', data?.booking_sub?.length && data?.booking_sub[0]['firstname']);
    // console.log('selectedPnr', selectedPnr, id);

    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form className={'AllForm'} onSubmit={handleSubmit} id="myForm">
                    <Modal.Header closeButton className="d-flex px-4 align-items-center">
                        <Modal.Title id="contained-modal-title-vcenter">Update Booking</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        {/*  */}
                        {!data?.booking_sub?.length &&
                            <div className="text-center">
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                            </div>
                        }
                        {data &&
                            <>
                                {/* {customer == 'Agent' &&
                                    <>
                                        <InputGroup className="form-group">
                                                <Form.Label>Agent Name</Form.Label>
                                                <Form.Control type={'text'} placeholder="Search Agent by name" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setData({ ...data, agent: e.target.value })}/>
                                                <Button variant={''} type={'button'} id={""} className={"fontweight700 F1F1F1bg fontsize14 blacktext"}>Add new Agent</Button>
                                            </InputGroup>
                                        <div className="text-end">
                                            <Link to={'#'} className='greentext' onClick={() => setAddAgent(true)}>Add new Agent</Link>
                                        </div>
                                        <InputGroup className="form-group">
                                            <Form.Label>Agent Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search Agent by name"
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                onChange={handleSearch}
                                                readOnly={selected}
                                                value={searchTerm}
                                                required
                                            />
                                            {selected &&
                                                <Button
                                                    type={''}
                                                    variant={''}
                                                    className={'fontweight700 greenbg fontsize14 whitetext'}
                                                    onClick={() => {
                                                        setSearchTerm('');
                                                        setSelected(false);
                                                        setData({ ...data, agent: 0 })
                                                        setError(null)
                                                    }}
                                                >
                                                    Select new Agent
                                                </Button>
                                            }
                                        </InputGroup>
                                        {searchTerm && !selected && (
                                            <div className='searchdropdow mt-1'>
                                                {loading ? (
                                                    <div className={'mt-3 w-100 text-center'}>
                                                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {searchResults.length > 0
                                                            ? searchResults.map((agent) => (
                                                                <NavItem
                                                                    className=''
                                                                    key={agent.id}
                                                                    onClick={() => handleAgentSelection(agent)}
                                                                >
                                                                    <p className='p-3'>{agent.name}</p>
                                                                </NavItem>
                                                            ))
                                                            : !selected && 'No Agent Found'}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </>
                                } */}
                                {/*  */}
                                {[...Array(parseInt(bookingType.Acount) + parseInt(bookingType.Ccount) + parseInt(bookingType.Icount))].map((_, index) => (
                                    <Row className={'my-4'} key={index}>
                                        <p className='fontsize16 d-flex align-items-center'>{index + 1} : Passengers Details -
                                            {index < parseInt(bookingType.Acount) &&
                                                <h4 className='fontsize18'>Adult</h4>}
                                            {index >= parseInt(bookingType.Acount) && index < parseInt(bookingType.Acount) + parseInt(bookingType.Ccount) &&
                                                <h4 className='fontsize18' >Child </h4>}
                                            {index >= parseInt(bookingType.Acount) + parseInt(bookingType.Ccount) &&
                                                <h4 className='fontsize18' >Infant</h4>}
                                        </p>
                                        <Col className={'mb-4 mb-lg-0'} xl={'2'} lg={'2'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Title</Form.Label>
                                                <Form.Select aria-label="Default select example"
                                                    as="select"
                                                    onChange={(event) => handleSelectChange(event, "title", index)}
                                                    value={data?.booking_sub[index]?.title || ''}
                                                    required
                                                    title={data?.booking_sub[index]?.title}
                                                >
                                                    <option className='blacktext' value={''}>Select title</option>
                                                    <option className='blacktext' value={'Mr'}>Mr.</option>
                                                    <option className='blacktext' value={'Mrs'}>Mrs.</option>
                                                    <option className='blacktext' value={'Ms'}>Ms.</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>First Namde/Given Name</Form.Label>
                                                <Form.Control type="text" name={''} id={''} placeholder={'First Name/Given Name'} required
                                                    // onChange={(e) => setData({ ...data, firstname: e.target.value })}
                                                    onChange={(event) => handleSelectChange(event, "firstname", index)}
                                                    value={data?.booking_sub?.length && data?.booking_sub[index]?.firstname || ''}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Last Name/Surname</Form.Label>
                                                <Form.Control type="text" name={''} id={''} placeholder={'Last Name/Surname'} required
                                                    // onChange={(e) => setData({ ...data, lastname: e.target.value })} 
                                                    onChange={(event) => handleSelectChange(event, "lastname", index)}
                                                    value={data?.booking_sub?.length && data?.booking_sub[index]?.lastname || ''}

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'2'} lg={'2'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Date of Birth</Form.Label>
                                                <Form.Control type="date" name={''} id={''} placeholder={'Date of Birth'} required max={new Date().toISOString().split("T")[0]}
                                                    // onChange={(e) => setData({ ...data, dob: e.target.value })} 
                                                    onChange={(event) => handleSelectChange(event, "dob", index)}
                                                    value={data?.booking_sub?.length && data?.booking_sub[index]?.dob || ''}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Passport Number</Form.Label>
                                                <Form.Control type="text" name={''} id={''} placeholder={'Passport Number'} required
                                                    // onChange={(e) => setData({ ...data, passport_no: e.target.value })}
                                                    onChange={(event) => handleSelectChange(event, "passport_no", index)}
                                                    value={data?.booking_sub?.length && data?.booking_sub[index]?.passport_no || ''}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Passport Expiry Date</Form.Label>
                                                <Form.Control type="date" name={''} id={''} placeholder={'Passport Expiry Date'} required
                                                    // onChange={(e) => setData({ ...data, passport_expiry_date: e.target.value })}
                                                    onChange={(event) => handleSelectChange(event, "passport_expiry_date", index)}
                                                    value={data?.booking_sub?.length && data?.booking_sub[index]?.passport_expiry_date || ''}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group d-flex" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Passport Photo/pdf</Form.Label>
                                                <div className='imageuploader me-2'>
                                                    <Form.Control
                                                        required={false} // Mark as required only when adding a new agent
                                                        type="file"
                                                        accept="image/*,application/pdf"
                                                        name={''}
                                                        id={''}
                                                        placeholder={'Passport Photo/pdf'}
                                                        onChange={(event) => handleFileSelectChange(event, "passport_img", index)}
                                                    />
                                                    {/* Show Error Message */}
                                                    {errors[index] && <p className="text-danger">{errors[index]}</p>}

                                                    {/* Show Previous File if Exists */}
                                                    {selectedFiles[`passport_img_${index}`] && (
                                                        <p>Selected File: {selectedFiles[`passport_img_${index}`]}</p>
                                                    )}
                                                    {data?.booking_sub?.[index]?.passport_img && !selectedFiles[`passport_img_${index}`] && (
                                                        <p>
                                                            Already Selected:
                                                            <Link to={`${API_BASE_URL_Main}/public/uploads/passports/${data.booking_sub[index].passport_img}`} target='_blank'>
                                                                View File
                                                            </Link>
                                                        </p>
                                                    )}                                          </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group d-flex" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Visa copy/pdf</Form.Label>
                                                <div className='imageuploader me-2'>
                                                    <Form.Control
                                                        required={false} // Mark as required only when adding a new agent
                                                        type="file"
                                                        accept="image/*,application/pdf"
                                                        name={''}
                                                        id={''}
                                                        placeholder={'Visa copy/pdf'}
                                                        onChange={(event) => handleFileSelectChange(event, "visa_copy", index)}

                                                    />
                                                    {/* Show Previous File if Exists */}
                                                    {selectedFiles[`visa_copy_${index}`] && (
                                                        <p>Selected File: {selectedFiles[`visa_copy_${index}`]}</p>
                                                    )}
                                                    {data?.booking_sub?.[index]?.visa_copy && !selectedFiles[`visa_copy_${index}`] && (
                                                        <p>
                                                            Already Selected:
                                                            <Link to={`${API_BASE_URL_Main}/public/uploads/passports/${data.booking_sub[index].visa_copy}`} target='_blank'>
                                                                View File
                                                            </Link>
                                                        </p>
                                                    )}                                                      </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                ))}
                                {/*  */}
                                {/* <Form.Check
                                    type="radio"
                                    label="Confirmed"
                                    checked={data?.confirmed === 1}
                                    name="confirmradio"
                                    id="radioConfirm"
                                    onClick={() => setData({ ...data, confirmed: data?.confirmed === 1 ? 0 : 1 })}
                                    onChange={() => setData({ ...data, confirmed: data?.confirmed === 1 ? 0 : 1 })}
                                />
                                {data.confirmed === 1 &&
                                    <Row className={'mt-4'}>
                                        <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Total Amount</Form.Label>
                                                <Form.Control type="number" name={''} id={''} placeholder={'Amount'}
                                                    onChange={(e) => setData({ ...data, total_amount: e.target.value })}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Received</Form.Label>
                                                <Form.Control type="number" name={''} id={''} placeholder={'Received'}
                                                    onChange={(e) => setData({ ...data, received_amount: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Remaining (auto)</Form.Label>
                                                <Form.Control type="text" readOnly name={''} id={''} placeholder={'Remaining'} value={data.total_amount - data.received_amount}
                                                // onChange={(e) => setData({ ...data, remaining_amount: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                } */}
                            </>
                        }


                        <p className='redtext mt-2'>{error}</p>
                    </Modal.Body>
                    <Modal.Footer className="">
                        <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                        <Button variant="" disabled={loading || error} type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg">
                            {loading ?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                : 'Save'
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {addAgent &&
                <AddAgent show={addAgent} setShow={setAddAgent} path='booking' />
            }
        </>
    )
}

export default EditTicket;